import styled, { css } from "styled-components";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { GTMBase } from "components";
import { media } from "utils/styles/breakpoints";
import { ArrowBack } from "@company-mrv/mrv-design-system/icons";
import { getThemePropertyValue } from "utils/pluginHelpers";

export const Background = styled.div`
  min-width: 100%;
  background-color: ${props => props.theme.BrandTokens.NeutralColors.XxLight};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonBack = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  background: ${tokens.colorBackground04};
`;

export const ButtonContainer = styled(GTMBase)`
  cursor: pointer;
  border: ${tokens.borderRadiusNone};
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${tokens.colorBackground04};
  width: ${props => (props.mobileBreak ? props.mobileBreak : "312px")};
  ${props =>
    !props.removePaddings &&
    css`
      padding-right: 24px;
      padding-left: 24px;
    `}

  ${media.forTabletUp} {
    width: 440px;
    padding: 0;
  }

  ${media.forDesktopUp} {
    width: 504px;
  }

  ${props =>
    props.widthFull &&
    css`
      padding: 0;
      width: 100% !important;
    `}
`;

export const ButtonBackIcon = styled(ArrowBack).attrs({
  color: getThemePropertyValue(
    "buttonBack.color",
    tokens.colorBrandSecondaryPure
  ),
  size: 24
})`
  width: 24px;
  height: 24px;
`;

export const ButtonBackLabel = styled.p`
  font-family: ${tokens.fontFamily01};
  font-weight: ${tokens.fontWeightSemibold};
  font-size: ${tokens.fontSizeXxSmall};
  line-height: ${tokens.lineHeightDefault};
  color: ${tokens.colorNeutralDark};
  margin-left: 5px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => (props.mobileBreak ? props.mobileBreak : "312px")};

  ${media.forTabletUp} {
    width: 440px;
  }

  ${media.forDesktopUp} {
    width: 504px;
  }

  max-width: ${props =>
    props.mobileBreakMax ? props.mobileBreakMax : "initial"};

  ${props =>
    props.widthFull &&
    css`
      width: 100% !important;
    `}
`;
