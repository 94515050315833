import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { getEmpreendimentos, getPerfil, getDados } from "services/finance";
import ContractContext from "contexts/contract";
import { trackException, trackTraceException } from "services/trackInsights";

const FinanceContext = React.createContext({
  perfilLoaded: false,
  perfilErrorCriFlex: false,
  loadingPerfil: false,
  loadPerfil: () => {},
  perfil: null,
  empreendimentos: null,
  errorPerfil: false
});

const TRY_RETRIES_COUNT_TOTAL = 2;

export const FinanceContextProvider = ({ children }) => {
  const [loadingPerfil, setLoadingPerfil] = useState(false);
  const [perfilLoaded, setPerfilLoaded] = useState(false);
  const [errorPerfil, setErrorPerfil] = useState(false);
  const [perfilErrorCriFlex, setPerfilErrorCriFlex] = useState(false);
  const [countTryPerfil, setCountTryPerfil] = useState(0);
  const { selectedContract } = useContext(ContractContext);
  const [perfil, setPerfil] = useState(null);
  const [empreendimentos, setEmpreendimentos] = useState(null);
  const [dados, setDados] = useState(true);
  const [lastContractSelected, setLastContractSelected] = useState(null);

  const loadPerfil = async () => {
    setLoadingPerfil(true);
    try {
      if (!dados) {
        const dadosReq = await getDados();
        setDados(dadosReq);
      }
      let empredimentosReq = empreendimentos;
      if (!empredimentosReq) {
        empredimentosReq = await getEmpreendimentos(
          lastContractSelected.contratoSap
        );
        setEmpreendimentos(empredimentosReq);
      }
      const empreendimentoSelecionado = empredimentosReq.find(
        empreendimento =>
          empreendimento.idEmpreendimento ===
          lastContractSelected.empreendimentoId
      );
      if (empreendimentoSelecionado && empreendimentoSelecionado.id) {
        const perfilReturned = await getPerfil(
          empreendimentoSelecionado.id,
          lastContractSelected.contratoSap
        );
        setPerfil(perfilReturned);
      }
      setPerfilErrorCriFlex(false);
      setCountTryPerfil(0);
      setPerfilLoaded(true);
    } catch (error) {
      const newCount = countTryPerfil + 1;
      setCountTryPerfil(newCount);
      if (error && error.response && error.response.status === 423) {
        if (error.response.data && error.response.data.Email) {
          setPerfilLoaded(true);
          setPerfilErrorCriFlex(true);
        }
      }
      if (newCount === TRY_RETRIES_COUNT_TOTAL) {
        setErrorPerfil(true);
      }
      trackException(error);
      trackTraceException("loadPerfil", error);
    } finally {
      setLoadingPerfil(false);
    }
  };

  useEffect(() => {
    const load = async () => {
      await loadPerfil();
    };
    if (
      countTryPerfil < TRY_RETRIES_COUNT_TOTAL &&
      !loadingPerfil &&
      !perfilLoaded &&
      lastContractSelected &&
      lastContractSelected.contratoSap
    ) {
      load();
    }
  }, [
    loadingPerfil,
    perfilLoaded,
    countTryPerfil,
    setCountTryPerfil,
    lastContractSelected,
    setLastContractSelected
  ]);

  useEffect(() => {
    if (
      lastContractSelected === null ||
      lastContractSelected.idEmpreendimento == null ||
      lastContractSelected.idEmpreendimento !==
        selectedContract.idEmpreendimento
    ) {
      setPerfilLoaded(false);
      setLastContractSelected(selectedContract);
    }
  }, [selectedContract]);

  const value = React.useMemo(
    () => ({
      loadingPerfil,
      perfilLoaded,
      perfilErrorCriFlex,
      loadPerfil,
      perfil,
      empreendimentos,
      errorPerfil
    }),
    [
      loadingPerfil,
      perfilLoaded,
      perfilErrorCriFlex,
      loadPerfil,
      perfil,
      empreendimentos,
      errorPerfil
    ]
  );

  return (
    <FinanceContext.Provider value={value}>{children}</FinanceContext.Provider>
  );
};

FinanceContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FinanceContext;
