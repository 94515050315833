import moment from "moment";
import { TIME_DAYS_RESET } from "utils/constants";

let timeoutId;

const LAST_DATE_ENTERED = "LAST_DATE_ENTERED";
const NOT_SHOW_MODAL = "NOT_SHOW_MODAL";
const NOT_SHOW_MODAL_SESSION = "NOT_SHOW_MODAL_SESSION";
const PASS_IN_ACOMPANHAR_OBRA = "PASS_IN_ACOMPANHAR_OBRA";
const FASES_DA_OBRA = "FASES_DA_OBRA";
const MODAL_QUER_SABER_MAIS = "MODAL_QUER_SABER_MAIS";
const CLOSE_MODAL_QUER_SABER_MAIS = "CLOSE_MODAL_QUER_SABER_MAIS";
const LAST_PAGE_ENTERED = "LAST_PAGE_ENTERED";
const ACOMPANHAR_OBRA = "ACOMPANHAR_OBRA";
const HOME = "HOME";

export const stopTimeout = () => {
  clearTimeout(timeoutId);
};

const makePathStorage = (cpf, idEmpreendimento, key) => {
  return `${cpf}-${idEmpreendimento}-${key}`;
};

export const resetNotModalFromTime = (cpf, idEmpreendimento) => {
  const lastDateEntered = localStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, LAST_DATE_ENTERED)
  );
  if (TIME_DAYS_RESET && lastDateEntered) {
    const days = Number(TIME_DAYS_RESET);
    const dateSaved = moment(lastDateEntered, "yyyy-MM-DD");
    const dateNow = moment();
    if (dateSaved.diff(dateNow, "days") > days) {
      localStorage.removeItem(
        makePathStorage(cpf, idEmpreendimento, LAST_DATE_ENTERED)
      );
      localStorage.removeItem(
        makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL)
      );
    }
  }
};

export const verifyModal = (cpf, idEmpreendimento, location, callback) => {
  resetNotModalFromTime(cpf, idEmpreendimento);
  const notShowModal = localStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL)
  );
  const passInAcompanharObra = sessionStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, PASS_IN_ACOMPANHAR_OBRA)
  );
  let notShowModalSession = sessionStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL_SESSION)
  );
  const fasesDaObra = sessionStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, FASES_DA_OBRA)
  );
  const modalQuerSaberMais = sessionStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, MODAL_QUER_SABER_MAIS)
  );
  const closeModalQuerSaberMais = sessionStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, CLOSE_MODAL_QUER_SABER_MAIS)
  );
  const lastPageEntered = sessionStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, LAST_PAGE_ENTERED)
  );

  if (notShowModalSession) {
    if (location.pathname === "/" && lastPageEntered === ACOMPANHAR_OBRA) {
      sessionStorage.removeItem(
        makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL_SESSION)
      );
    } else if (
      location.pathname === "/relacionamento/acompanhe-sua-obra" &&
      lastPageEntered === HOME
    ) {
      sessionStorage.removeItem(
        makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL_SESSION)
      );
    }
  }

  notShowModalSession = sessionStorage.getItem(
    makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL_SESSION)
  );

  if (!!notShowModal || !!notShowModalSession) return;

  if (location.pathname === "/") {
    sessionStorage.setItem(
      makePathStorage(cpf, idEmpreendimento, LAST_PAGE_ENTERED),
      HOME
    );
    sessionStorage.setItem(
      makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL_SESSION),
      NOT_SHOW_MODAL_SESSION
    );
    stopTimeout();
    callback();
    return;
  }

  if (closeModalQuerSaberMais) {
    sessionStorage.setItem(
      makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL_SESSION),
      NOT_SHOW_MODAL_SESSION
    );
    stopTimeout();
    callback();
    return;
  }

  if (location.pathname === "/relacionamento/acompanhe-sua-obra") {
    sessionStorage.setItem(
      makePathStorage(cpf, idEmpreendimento, LAST_PAGE_ENTERED),
      ACOMPANHAR_OBRA
    );
    sessionStorage.setItem(
      makePathStorage(cpf, idEmpreendimento, PASS_IN_ACOMPANHAR_OBRA),
      PASS_IN_ACOMPANHAR_OBRA
    );
    if (passInAcompanharObra && fasesDaObra) {
      sessionStorage.setItem(
        makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL_SESSION),
        NOT_SHOW_MODAL_SESSION
      );
      stopTimeout();
      callback();
      return;
    }
    stopTimeout();
    if (!modalQuerSaberMais) {
      timeoutId = setTimeout(() => {
        sessionStorage.setItem(
          makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL_SESSION),
          NOT_SHOW_MODAL_SESSION
        );
        callback();
      }, 8000);
    }
  }
};

export const resetNotModal = (cpf, idEmpreendimento) => {
  localStorage.removeItem(
    makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL)
  );
};

export const goToModalQuerSaberMais = (cpf, idEmpreendimento) => {
  stopTimeout();
  sessionStorage.setItem(
    makePathStorage(cpf, idEmpreendimento, MODAL_QUER_SABER_MAIS),
    MODAL_QUER_SABER_MAIS
  );
};

export const closeModalQuerSaberMais = (cpf, idEmpreendimento) => {
  stopTimeout();
  sessionStorage.setItem(
    makePathStorage(cpf, idEmpreendimento, CLOSE_MODAL_QUER_SABER_MAIS),
    CLOSE_MODAL_QUER_SABER_MAIS
  );
};

export const goToFasesDaObra = (cpf, idEmpreendimento) => {
  sessionStorage.setItem(
    makePathStorage(cpf, idEmpreendimento, FASES_DA_OBRA),
    FASES_DA_OBRA
  );
};

export const closeModalByCallMia = (cpf, idEmpreendimento) => {
  localStorage.setItem(
    makePathStorage(cpf, idEmpreendimento, LAST_DATE_ENTERED),
    moment().format("yyyy-MM-DD")
  );
  localStorage.setItem(
    makePathStorage(cpf, idEmpreendimento, NOT_SHOW_MODAL),
    NOT_SHOW_MODAL
  );
};
