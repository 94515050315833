import mockBdd from "utils/mock";
import Auth from "./auth";

export const axiosInstance = async () => {
  // await new Promise((resolve, reject) => {setTimeout(() => resolve(defaultToken), 2000)});
  const user = await Auth.getUser();
  const { id_token: token } = user || {};
  const mock = mockBdd[token];
  return mock;
};
